/* You can add global styles to this file, and also import other style files */

@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

body {
  padding-top: 54px;
}

html, body { height: 100%; }

.h-container {
  min-height: 95%;
}



@media (min-width: 992px) {
  body {
    padding-top: 56px;
  }
}

.margin-left{
  margin-left: 80px !important;
}

.margin-right{
  margin-right: 80px !important;
}

.row-centered {
  text-align:center;
  align-items: center;
}

.col-centered {
  display:inline-block;
  float:none;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
}

.btn-space {
  margin-right: 5px;
  margin-bottom: 5px;
}

.btn-group-border {
  border: 125px;
  border-top: 100px;
  margin-top: 15px;
}

.image-border {
  border: 125px;
  border-top: 100px;
  margin-top: 15px;
}


.card-space {
  margin-right: 5px;
}

.row-centered {
  text-align:center;
  align-items: center;
}

.col-centered {
  display:inline-block;
  float:none;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 100px;
  text-align: center;
}


